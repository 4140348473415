

// Sidebar event  
function openSideBar(parm) {
    document.getElementById("mySidebar").style.width = "290px";  
    init(); 
    if(parm == 'pf'){
        document.getElementById("mySidebar").style.overflowY = 'visible';       
    }
    document.getElementById(parm).style.display = "block"
}


function init () {
    document.getElementById("bp").style.display = "none"
    document.getElementById("es").style.display = "none"
    document.getElementById("ba").style.display = "none"
    document.getElementById("cs").style.display = "none"
    document.getElementById("pf").style.display = "none"
    document.getElementById("gv").style.display = "none"
    document.getElementById("rt").style.display = "none"
    document.getElementById("ts").style.display = "none"
    

    for (i = 0; i < 12; i++) { 
        document.getElementById(i.toString()).style.display = "none"
    }


}
  
function closeNav() {
    document.getElementById("mySidebar").style.width = "0";
    document.getElementById("mySidebar").style.overflowY = 'hidden';
}
